<template>
  <div></div>
</template>
<script>
import weixin from '@/common/weixin'
export default {
  name:"mini",
  created(){
    let openid = this.$tools.getCookie("openid")
    let url = this.$tools.getURL("/pages/user/bind",{openid:openid})
    weixin.wx.miniProgram.redirectTo({url})
    // if(!this.$route.query.code){
    //   this.$tools.jumpToGetOpenid({query:this.$route.query})
    //   // location.href="http://192.168.1.141:8080/?path=mini&code=111"
    //   return;
    // }
    // // let url = this.$tools.getURL(this.$urls.openid,this.$route.query)
    // let requestURL = this.$tools.getURL(this.$urls.bind,{...this.$route.query})
    // this.$axios.get(requestURL).then(res=>{
    //   let url = this.$tools.getURL("/pages/user/bind",{openid:res.openid})
    //   if(this.$route.query.message == 1){
    //     try{
    //       weixin.wx.miniProgram.postMessage({data:{openid:res.openid}})
    //     }catch(err){
    //       weixin.wx.miniProgram.redirectTo({url,err:JSON.stringify(err)})
    //     }
    //   }else{
    //     weixin.wx.miniProgram.redirectTo({url})
    //   }
    // })
  },
}
</script>